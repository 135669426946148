<template>
  <b-card>
    <div
      data-v-4d50203e=""
      class="media"
    >
      <div
        data-v-4d50203e=""
        class="media-aside align-self-start"
      >
        <b-avatar
          :src="getAvatar(user.avatar)"
          :text="getInitials(user.name)"
          variant="light-primary"
        />
      </div>
      <div class="media-body">
        <h6>{{ user.name }}</h6>
        <p class="mb-0 small">
          Normal User
        </p>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label="Alias">
              <validation-provider
                #default="{ errors }"
                name="alias"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserPlusIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userInfo.alias"
                    placeholder="Alias"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Phone Number">
              <validation-provider
                #default="{ errors }"
                name="phone number"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneCallIcon" />
                  </b-input-group-prepend>
                  <cleave
                    id="phoneNumber"
                    v-model="userInfo.phoneNumber"
                    class="form-control"
                    :raw="false"
                    :options="clevePhone"
                    placeholder="Phone Number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Mobile Number 1">
              <validation-provider
                #default="{ errors }"
                name="mobile number"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneCallIcon" />
                  </b-input-group-prepend>
                  <cleave
                    id="phone"
                    v-model="userInfo.mobileNumber"
                    class="form-control"
                    :raw="false"
                    :options="clevePhone"
                    placeholder="Mobile Number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Mobile Number 2">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="PhoneCallIcon" />
                </b-input-group-prepend>
                <cleave
                  id="phone-2"
                  v-model="userInfo.mobileNumber2"
                  class="form-control"
                  :raw="false"
                  :options="clevePhone"
                  placeholder="Mobile Number 2"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Country">
              <validation-provider
                #default="{ errors }"
                name="country"
                rules="required"
              >
                <b-input-group>
                  <v-select
                    id="countryList"
                    v-model="userInfo.country"
                    placeholder="Select country"
                    style="width: 100%"
                    label="name"
                    :reduce="item => item.code"
                    :options="countryOption"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Street">
              <validation-provider
                #default="{ errors }"
                name="street"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userInfo.streetAddress"
                    placeholder="Street"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="District">
              <validation-provider
                #default="{ errors }"
                name="district"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userInfo.district"
                    placeholder="district"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Postal Code">
              <validation-provider
                #default="{ errors }"
                name="postal code"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userInfo.postalCode"
                    placeholder="Postal Code"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              type="submit"
              :disabled="isProcessing"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BCard,
    Cleave,
    vSelect,
    BAvatar,
    BSpinner,
  },
  props: {
    userInfoData: {
      type: Array,
      default: () => [],
    },
    countryOption: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      email,
      userInfo: {
        id: null,
        alias: '',
        mobileNumber: '',
        streetAddress: '',
        country: '',
        postalCode: '',
      },
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      isProcessing: false,
      user: getUserData(),
    }
  },
  watch: {
    userInfoData(newValue) {
      if (newValue[0]) {
        const userInfo = { ...newValue[newValue.length - 1] }
        userInfo.country = userInfo.country?.code
        this.userInfo = userInfo
      }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const { userInfo } = this
          delete userInfo.__typename
          useApollo.users.postGeneralSetting({
            userUid: getUserData().uuid,
            input: userInfo,
          }).then(response => {
            this.$emit('refetch')
            this.$store.dispatch('project/setInformationStatus', {
              hasGeneralInformation: true,
            })
            this.showSuccess(response.data.updateUserContactInformation.message)
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>
